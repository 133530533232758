import gql from 'graphql-tag'

export const LOGIN_USER = gql`
mutation login ($email: String! $password: String! ){
  login(email: $email password: $password ){
    token
  }
}
`
export const REGISTER_USER = gql`
mutation registerUser($user_name: String! $email: String! $password: String! ) {
  registerUser( user_name: $user_name, email: $email, password: $password) {
      token
    }
}
`
export const DELETE_WATCH_LIST = gql`
  mutation deleteWatchList ($id: ID!){
    deleteWatchList(id: $id){
        id
        user_name
        email,
        watchLists{
          id
          name
          tickers{
            id
            symbol
            name
          }
        }
    }
  }
`

export const CREATE_WATCH_LIST = gql`
  mutation watchListCreate ( $userid: ID!, $name: String){
    watchListCreate( userid: $userid, name: $name){
      id
        user_name
        email,
        watchLists{
          id
          name
          tickers{
            id
            symbol
            name
          }
        }
    }
  }
`
export const ADD_TICKER_TO_WATCH_LIST = gql`
  mutation addTickerToWatchList ( $watchListId: ID!, $tickerId: ID!){
    addTickerToWatchList( watchListId: $watchListId, tickerId: $tickerId){
      id
        user_name
        email,
        watchLists{
          id
          name
          tickers{
            id
            symbol
            name
          }
        }
    }
  }
`
export const DELETE_TICKER_FROM_WATCH_LIST = gql`
  mutation deleteTickerFromWatchList ( $watchListId: ID!, $tickerId: ID!){
    deleteTickerFromWatchList( watchListId: $watchListId, tickerId: $tickerId){
      id
        user_name
        email,
        watchLists{
          id
          name
          tickers{
            id
            symbol
            name
          }
        }
    }
  }
`
export const CHANGE_WATCH_LIST_NAME = gql`
  mutation changeWatchListName ( $watchListId: ID!, $watchListName: String!){
    changeWatchListName( watchListId: $watchListId, watchListName: $watchListName){
      id
        user_name
        email,
        watchLists{
          id
          name
          tickers{
            id
            symbol
            name
          }
        }
    }
  }
`
