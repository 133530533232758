import Vue from 'vue'
import Vuex from 'vuex'
import { onLogout, apolloClient } from '@/vue-apollo'
import {
  LOGGED_IN_USER,
  GET_ONE_TICKER,
  GET_ALL_TICKERS,
  GET_STOCK_QUOTE,
  GET_STOCK_TIME_SEREIES,
  GET_OPTIONS_CHAIN
} from '@/graphql/queries'
import {
  LOGIN_USER,
  REGISTER_USER,
  DELETE_WATCH_LIST,
  CREATE_WATCH_LIST,
  ADD_TICKER_TO_WATCH_LIST,
  DELETE_TICKER_FROM_WATCH_LIST,
  CHANGE_WATCH_LIST_NAME
} from '@/graphql/mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    user: {},
    ticker: {},
    tickerList: [],
    authStatus: false,
    stock_quote: {},
    stock_time_series: {},
    stock_options_chain: []
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.authStatus,
    user: state => state.user,
    ticker: state => state.ticker,
    tickerList: state => state.tickerList,
    stockQuote: state => state.stock_quote,
    stockTimeSeries: state => state.stock_time_series,
    stockOptionsChain: state => state.stock_options_chain
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    LOGIN_USER (state, user) {
      state.authStatus = true
      state.user = { ...user }
    },
    LOGOUT_USER (state) {
      state.authStatus = ''
      state.token = '' && localStorage.removeItem('apollo-token')
    },
    SET_TICKER (state, newticker) {
      state.ticker = { ...newticker }
    },
    SET_TICKER_LIST (state, tickerlist) {
      state.tickerList = tickerlist
    },
    SET_STOCK_QUOTE (state, newquote) {
      state.stock_quote = newquote
    },
    SET_STOCK_TIME_SERIES (state, timeSeries) {
      state.stock_time_series = timeSeries
    },
    SET_STOCK_OPTIONS_CHAIN (state, optionsChain) {
      state.stock_options_chain = optionsChain
    }
  },
  actions: {
    async register ({ commit, dispatch }, authDetails) {
      const { data } = await apolloClient.mutate({ mutation: REGISTER_USER, variables: { ...authDetails } })
      const token = JSON.stringify(data.registerUser.token)
      commit('SET_TOKEN', token)
      // onLogin(apolloClient, user.token)
      localStorage.setItem('apollo-token', token)
      dispatch('setUser')
    },
    async login ({ commit, dispatch }, authDetails) {
      const { data } = await apolloClient.mutate({ mutation: LOGIN_USER, variables: { ...authDetails } })
      const token = JSON.stringify(data.login.token)
      commit('SET_TOKEN', token)
      localStorage.setItem('apollo-token', token)
      dispatch('setUser')
    },
    async setUser ({ commit }) {
      const { data } = await apolloClient.query({ query: LOGGED_IN_USER })
      commit('LOGIN_USER', data.me)
    },
    async logOut ({ commit, dispatch }) {
      commit('LOGOUT_USER')
      onLogout(apolloClient)
    },
    async getSingleTicker ({ commit, dispatch }, id) {
      const { data } = await apolloClient.query({ query: GET_ONE_TICKER, variables: { id } })
      commit('SET_TICKER', { ...data.ticker })
    },
    async updateTickerList ({ commit, dispatch }) {
      const { data } = await apolloClient.query({ query: GET_ALL_TICKERS })
      commit('SET_TICKER_LIST', { ...data.allTickers })
    },
    async deleteWatchList ({ commit, dispatch }, id) {
      const { data } = await apolloClient.mutate({ mutation: DELETE_WATCH_LIST, variables: { id } })
      commit('LOGIN_USER', { ...data.deleteWatchList })
    },
    async watchListCreate ({ commit, dispatch, state }, watchListName) {
      const { data } = await apolloClient.mutate({ mutation: CREATE_WATCH_LIST, variables: { userid: state.user.id, name: watchListName } })
      commit('LOGIN_USER', { ...data.watchListCreate })
    },
    async addTickerToWatchList ({ commit, dispatch }, options) {
      const { data } = await apolloClient.mutate({ mutation: ADD_TICKER_TO_WATCH_LIST, variables: { watchListId: options.watchListId, tickerId: options.tickerId } })
      commit('LOGIN_USER', { ...data.addTickerToWatchList })
    },
    async deleteTickerFromWatchList ({ commit, dispatch }, options) {
      const { data } = await apolloClient.mutate({ mutation: DELETE_TICKER_FROM_WATCH_LIST, variables: { watchListId: options.watchListId, tickerId: options.tickerId } })
      commit('LOGIN_USER', { ...data.deleteTickerFromWatchList })
    },
    async changeWatchListName ({ commit, dispatch }, watchListProperties) {
      const { data } = await apolloClient.mutate({ mutation: CHANGE_WATCH_LIST_NAME, variables: { watchListId: watchListProperties.watchListId, watchListName: watchListProperties.watchListName } })
      commit('LOGIN_USER', { ...data.changeWatchListName })
    },
    async stockQuote ({ commit, dispatch }, ticker) {
      const { data } = await apolloClient.query({ query: GET_STOCK_QUOTE, variables: { ticker } })
      commit('SET_STOCK_QUOTE', JSON.parse(data.getStockQuote).quotes.quote)
    },
    async stockTimeSeries ({ commit, dispatch }, ticker) {
      const { data } = await apolloClient.query({ query: GET_STOCK_TIME_SEREIES, variables: { ticker } })
      commit('SET_STOCK_TIME_SERIES', JSON.parse(data.getStockTimeSeries).series.data)
    },
    // eslint-disable-next-line camelcase
    async stockOptionsChain ({ commit, dispatch }, { ticker, expiration_date }) {
      const { data } = await apolloClient.query({ query: GET_OPTIONS_CHAIN, variables: { ticker, expiration_date } })
      commit('SET_STOCK_OPTIONS_CHAIN', JSON.parse(data.getOptionsChain).options.option)
    }
  }

})
