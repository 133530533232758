import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=>import('../components/Home.vue'),
    meta: {
      title: 'Home',
      requiresAuth: true
    }
  },
  {
    path: '/overview/:ticker',
    name: 'overview',
    component: ()=>import('../components/Overview.vue'),
    meta: {
      title: 'Overview',
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: ()=>import('../components/Settings.vue'),
    meta: {
      title: 'Settings',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../components/Login.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: ()=>import('../components/Register.vue'),
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/list',
    name: 'list',
    component: ()=>import('../components/StockList.vue'),
    meta: {
      title: 'List',
      requiresAuth: true
    }
  },
  {
    path: '/options/:ticker',
    name: 'options',
    component: ()=>import('../components/OptionsList.vue'),
    meta: {
      title: 'Options',
      requiresAuth: true
    }
  },
  {
    path: '/*',
    component: ()=>import('../components/PageNotFound.vue'),
    meta: {
      title: '404 Page Not Found'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes

})

// Global Route Guards

router.beforeEach((to, from, next) => {
  // Check if the user is logged in

  const isUserLoggedIn = store.getters.isAuthenticated

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      store.dispatch('logOut')
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
