import gql from 'graphql-tag'

export const LOGGED_IN_USER = gql`
  query {
    me {
      id
      user_name
      email,
      watchLists{
        id
        name
        tickers{
          id
          symbol
          name
        }
      }
    }
  }
`
export const GET_ONE_TICKER = gql`
  query($id: String!){
    ticker(id: $id) {
      id
      symbol
      name
    }
  }
`
export const GET_ALL_TICKERS = gql`
  query{
  allTickers{
    id
    symbol
    name
  }
}
`
export const GET_OPTIONS_CHAIN = gql`
  query($ticker: String! $expiration_date: String!){
    getOptionsChain(ticker: $ticker expiration_date: $expiration_date)
  }
`
export const GET_STOCK_QUOTE = gql`
  query($ticker: String!){
    getStockQuote(ticker: $ticker)
  }
`
export const GET_STOCK_TIME_SEREIES = gql`
  query($ticker: String!){
    getStockTimeSeries(ticker: $ticker)
  }
`

export const GET_STOCK_OPTION_CHAIN = gql`
  query($ticker: String!){
    getStockTimeSeries(ticker: $ticker)
  }
`
